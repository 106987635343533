import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

require("es6-promise").polyfill()

const smartfeedNotFound = ({ id = 1 }) => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="notfound">
        <div class="hero hero-black">
          <div class="container">
            <div class="holder">
              <div class="wrap-in">
                <div class="row">
                  <div class="col-lg-5 col-xl-4">
                    <div class="text-block">Page not found</div>
                  </div>
                </div>
              </div>
              <span class="triangle"></span>
            </div>
          </div>
          <figure class="img">
            <div class="img-hold ">
              <img
                width="1240"
                height="460"
                src="https://cdn1.accropress.com/Activate%20Learning/single_page_header.png"
                class="attachment-hero-bg size-hero-bg ls-is-cached lazyloaded"
                alt=""
                loading="lazy"
              />
            </div>
          </figure>
        </div>
      </div>
    </Layout>
  )
}

export default smartfeedNotFound
